export default function (amount: string, evt: Event): void {
	const inputValue = amount
	const dotPos = inputValue.indexOf('.')
	const splitAmount = inputValue.split('.')

	// limit to two numbers after decimal
	if (dotPos > -1 && splitAmount[1].length > 1) {
		evt.preventDefault()
	}
}
